header#amg-header.header-min { transform: translateY(-2.25em); transition: all 0.5s 0.5s ease-in-out; }

/*******************If we have comonent within components and have theme level changes, handle here********************************/
#app-comp.add-v1-css .search-bar-class {
    padding: 1em 1.85em 1em;
    order: 3;
}

#app-comp.add-v1-css .tag-button {
    padding: 0.25em 0.75em 0.25em 0.75em;
}

#app-comp.add-v1-css .filter-top-section {
    padding: 0;
}

#app-comp.add-v1-css .sub-menu-container {
    padding: 1em 1.5em 0;
}