@use 'sass:map';
@use '../core/theming/theming';
@use '../core/style/form-common';


// Theme styles that only apply to the standard appearance of the form-field.

@mixin standard-color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $is-dark-theme: map.get($config, is-dark);
  $underline-color:
    theming.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.7, 0.42));

  .mat-form-field-appearance-standard {
    .mat-form-field-underline {
      background-color: $underline-color;
    }

    &.mat-form-field-disabled .mat-form-field-underline {
      @include form-common.private-control-disabled-underline($underline-color);
    }
  }
}

@mixin standard-typography($config-or-theme) {}

@mixin private-form-field-standard-density($config-or-theme) {}

@mixin standard-theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-form-field-standard') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include standard-color($color);
    }
    @if $density != null {
      @include private-form-field-standard-density($density);
    }
    @if $typography != null {
      @include standard-typography($typography);
    }
  }
}
