// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ntg7-admin-web-app-primary: mat.define-palette(mat.$indigo-palette);
$ntg7-admin-web-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ntg7-admin-web-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ntg7-admin-web-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $ntg7-admin-web-app-primary,
      accent: $ntg7-admin-web-app-accent,
      warn: $ntg7-admin-web-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ntg7-admin-web-app-theme);

/* master style for responsiveness */
@import url(assets/css/master_style.css) only screen and (min-device-width: 581px),(min-width: 581px);
@import url(assets/css/master_style_mobile.css) only screen and (max-device-width: 580px),(max-width: 768px);
/* You can add global styles to this file, and also import other style files */
html,
body {
  height: auto;
  padding: 0; 
  margin: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.admin-console-wrapper {
    width: 100%;
    background:  var(--bgprimary);
    margin: 0;
    padding: 0;
    color: #fff;
}

.content-container {
    min-height: calc(100vh);
}

body {
    background: var(--bgprimary) ;
}

.disable-dbl-tap-zoom {
  touch-action: none;
}

h1, h2 {
   font-weight: bold;
}

.wb-content-grid {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  min-width: 18em;
}

.disabledIcon {
  cursor: not-allowed;
}

.leaflet-popup-tip {
   width: 0px !important; 
   height: 0px !important; 
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background: var(--bgblack) !important;
  color: white !important;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}


.geoman-class .leaflet-div-icon {
    background: var(--bgamgred) !important;
}

.geoman-class .marker-icon,
.geoman-class .marker-icon:focus {
  background-color: var(--bgamgred) !important;
  border-color: var(--bgamgred) !important;
}



.geoman-class .marker-icon-middle {
  display: none;
}



.leaflet-edit-resize {
    background: #ffff !important;
    height: 30px !important;
    width: 30px !important;
    margin-left: -17px !important;
    margin-top: -10px !important; 
    background-image: url("assets/images/resize.PNG")!important; 
    background-size: 20px 15px !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: center !important; 
}

.leaflet-draw-tooltip {
  margin-left:30px;
}

.geoman-class .leaflet-interactive {
  stroke:var(--bgamgred);
  
}

.geoman-class .text-labels {
  padding: 6px !important;
  font-size: 1em !important;
  border-radius: 4px !important;
  font-weight: bold !important;
  margin-left: 4vw !important;
  background-color: white !important;
  margin-top: -20px !important;
  width: 65px !important;
  height: 35px !important;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 50%) !important;
}

.slick-initialized .slick-slide{
  display: flex !important;
}

.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  padding: 1em 0 0 0;
  gap: 0.5em;
  width: 100%;
  overflow: visible;
}


.slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
  padding: 1em;
  gap: 1em;
  width: auto !important;
}

.track-class .slick-slider .slick-track {
  transform: translate3d(0, 0, 0);
  padding-left: 1.5em;
  width: 100%;
}

.track-class .slick-next {
  display: none !important;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: none !important;
}

.slick-track {
    display: flex !important;
    // margin-left:16px !important;
}

.slick-dots {
  display: none !important;
}

.slick-prev:before {
  content: "";
  display: none;
}

.slick-slide
{
    height: inherit !important;
}

.zoom-margin {
  margin-bottom: 20em !important;
}
// .geoman-class .leaflet-marker-draggable {
//   background-color: white !important;
// }


.leaflet-draw-edit-edit{
  display: none !important;
}

.leaflet-container a{
  display:none;
}

.leaflet-touch .leaflet-bar a:first-child {
  background: var(--bgblack) !important;
  color: white !important;
}

.leaflet-touch .leaflet-bar a:last-child {
  background: var(--bgblack) !important;
  color: white !important;
}


.dealer-number-icon {
  background: url("assets/locations/tour-pins/waypoint-icon1.svg") no-repeat scroll 50% 0 transparent;
  background-size: contain;
  text-align: center;
  color: #ffffff;
  padding: 0.25em 0 !important;
  font-family: 'MBCorpoSTextOffice-Bold';
  font-size: 1.4em;
  line-height: 1.5em;
}

.gpx-icon-style {
  padding: 0.06em 1em 1.5em 0.5em;
  background-image: url("assets/locations/upload-emotion.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.add-icon-class {
  background-image: url("assets/images/add.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.track-icon-class {
  background-image: url("assets/images/icon-track.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.emotion-icon-class {
  background-image: url('assets/images/emotion.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.off-road-icon-class {
  background-image: url('assets/images/off-road.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.search-icon-class {
  background-image: url('assets/locations/search.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.dealer-icon-class {
  background-image: url('assets/locations/dealer-map-button.PNG');
  height: 3em;
  width: 3em;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 1px;
  flex: none;
}


.add-poi-icon-class {
  background-image: url('assets/locations/waypoint-icon.svg');
  height: 3em;
  width: 3em;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.add-poi-icon-class-non-amg-highlights {
  background-image: url('assets/locations/waypoint-icon.svg');
  height: 3em;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

//custome datepicker style

.emotion-tour-noise{
  background-image: url("assets/locations/emotionpin.svg");
}
.circuit-noise{
  background-image: url("assets/locations/circuitpin.svg"); 
}
.mycircuit{
  background-image: url("assets/locations/circuit-clustericon.svg"); 
  text-align:center;
  color:#fff; 
  padding-top: 1em !important;
}
.myemotiontour{
  background-image: url("assets/locations/emotion-tour-clustericon.svg"); 
  text-align:center;
  color:#fff; 
  padding-top: 1em !important;
}

#amg_admin .ngb-dp-months{
  background:#222;
}
#amg_admin .ngb-dp-header{
  background-color:#222;
  padding: 0.23em !important;
}
  
#amg_admin .ngb-dp-weekday {
  color: #ddd;
}
#amg_admin .btn-light.bg-primary {
--bs-bg-opacity: 0 !important;
background-color: #D9252C !important;
}
#amg_admin ngb-datepicker{
  z-index: 9999;
}
#amg_admin .leaflet-popup.dealer-custompopup {
  min-width: 16em;
  width: fit-content;
  width: -webkit-fit-content;
  height: fit-content;
  height: -webkit-fit-content;
  left: 2.5em !important;
  bottom: 0 !important;
  margin: 0;
}
#amg_admin .leaflet-popup.dealer-custompopup .leaflet-popup-content-wrapper {
  border-radius: var(--br-card-default);
  padding: 1em;
  .leaflet-popup-content {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap:0.5em
  }
}

#amg_admin .leaflet-popup.dealer-custompopup h1 { 
  font-size: 1.5em; 
  font-weight: 600 !important;
  margin: 0;
  
}
#amg_admin .leaflet-popup.dealer-custompopup small { 
  font-size: 1em;
  color: var(--textgrey1);
  display: block;
  margin: 0;
}
#amg_admin .leaflet-popup.dealer-custompopup p { 
  font-size: 1.3em; 
  margin: 0;
}
#amg_admin .leaflet-container .leaflet-marker-pane .start-marker,
#amg_admin .leaflet-container .leaflet-marker-pane .destination-marker {
  z-index: 999 !important;
}

#amg_admin .leaflet-container .leaflet-marker-pane .selected-destination,
#amg_admin .leaflet-container .leaflet-marker-pane .selected-start {
  z-index: 1001 !important;
}


#amg_admin .text-muted { color: #6c757d !important;}

#amg_admin .btn-light { color: #fff; }
#amg_admin .btn-light:active,
#amg_admin .btn-light.bg-primary, 
#amg_admin .btn-check:checked,
#amg_admin .btn-check:active,
#amg_admin .btn-light.active,
#amg_admin .btn-light.bg-primary.text-white, 
#amg_admin .btn-light:hover {
  color: #fff;
  background-color: #D9252C;
  border-color: #990e13;
}
#amg_admin .form-control:focus{
  outline: none !important;
  box-shadow: none;
}

#amg_admin .ngb-dp-navigation-chevron {
  width: 1.125em;
  height: 1.125em; 
}
#amg_admin{
  input:focus{
   outline: 0 !important;
  }
  .cus-date-picker .dropdown-menu  {
    width: fit-content;
    width: -webkit-fit-content;
  }
  .dropdown-menu.show { 
    box-shadow: 0 0.5em 0.75em #00000080; 
    font-size: 1em;
    width: fit-content;
    width: -webkit-fit-content;
    .ngb-dp-months{
      padding: 1em 1.25em;
    }
    .ngb-dp-navigation-select{
      gap: 0.3em;
    }
    .ngb-dp-navigation-select > select.custom-select {
      font-size: 1.125em;
      background-color: #222;
      border-radius: var(--br-card-default);
      border-color: #aaaaaa80;
      color: #fff;
      min-width: inherit;
    }
  }
  .btn-link {
    color: #D9252C;
    &:disabled{
      color: #676767;
    }
  }
}  
