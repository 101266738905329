/*Breaking points

580 - 320 : Mobile
959 - 581 : Tablet
1200 - 960 : PC
 

/**************Skeleton*********************/

.col-tablet-1 { width: 12.5%; }
.col-tablet-2 { width: 25%; }
.col-tablet-3 { width: 37.5%; }
.col-tablet-4 { width: 50%; }
.col-tablet-5 { width: 62.5%; }
.col-tablet-6 { width: 75%; }
.col-tablet-7 { width: 87.5%; }
.col-tablet-8 { width: 100%; }

/*******************************************/

@media only screen 
	and (min-width:581px) 
	and (max-width:1279px) {
	
	.clm-tab-1 { width: 12.5%; }
	.clm-tab-2 { width: 25%; }
	.clm-tab-3 { width: 37.5%; }
	.clm-tab-4 { width: 50%; }
	.clm-tab-5 { width: 62.5%; }
	.clm-tab-6 { width: 75%;}
	.clm-tab-7 { width: 87.5%; }
	.clm-tab-8 { width: 100%; }

	.lt-row-w-g > .clm-tab-1 { width: calc(12.5% - 1em); }
	.lt-row-w-g > .clm-tab-2 { width: calc(25% - 1em); }
	.lt-row-w-g > .clm-tab-3 { width: calc(37.5% - 1em); }
	.lt-row-w-g > .clm-tab-4 { width: calc(50% - 1em); }
	.lt-row-w-g > .clm-tab-5 { width: calc(62.5% - 1em); }
	.lt-row-w-g > .clm-tab-6 { width: calc(75% - 1em);}
	.lt-row-w-g > .clm-tab-7 { width: calc(87.5% - 1em); }
	.lt-row-w-g > .clm-tab-8 { width: 100%; }


}


@media only screen
	and (min-width:1025px)
	and (max-height: 960px) {

	body { font-size: 1.1111112vw;}
	body#amg_admin { font-size: 1.625dvh;}

}


@media only screen 
	and (max-width:1279px) 
	and (max-height:960px) {
	
	body { font-size: 1.5dvh !important;}

}



@media only screen 
	and (min-width:581px) 
	and (max-width:768px) {

    body { margin: 0;}

}


@media only screen 
	and (min-width:769px)
	and (max-width:959px) {

	body { margin: 0;}


	
}

@media only screen 
	and (min-width: 960px)
	and (max-width: 1024px) {
	
	body  { font-size: 0.75em; }

	
}

@media only screen 
	and (min-width:1025px) 
	and (max-width:1279px) {

	body  { font-size: 0.675em !important; }
	.web-portal-container { width: calc(100% - 6em);}

	
}

@media only screen 
	and (max-device-width:1180px)
	and (max-width: 820px) 
	and (orientation: portrait) {
	body { font-size: 1.325vw !important; }
}



@media only screen 
	and (min-width:1280px) {
	
	body { margin: 0;}

	
	
}

@media only screen 
	and (min-width:1280px)
	and (max-width:1440px)	{
	
	body { margin: 0;}

	
	
}


@media only screen 
    and (device-width: 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
	
	body { margin: 0;}

	
}

@media only screen 
    and (min-width: 800px) 
    and (height : 600px) { 

	body { margin: 0;}
	
}


@media only screen
    and (max-width: 1024px) {
   
	.web-portal-container { width: calc(100% - 4em);}
       
}

@media only screen
	and (max-device-width: 1024px)
    and (max-width: 768px) {

    body  { font-size: 0.75em !important; }
	
	
        
}

@media only screen
	and (max-device-width: 1024px)
	and (max-width: 768px)
	and (device-height: 1024px)
    and (orientation: portrait) {

    body  { font-size: 1vh !important; }
    
}

@media only screen
	and (max-device-width: 1024px)
	and (min-width: 1024px)
    and (orientation: landscape) {

	body  { font-size: 0.875vw !important; }
    
}

@media only screen 
    and (min-width: 600px)
    and (orientation: portrait) {

    body { margin: 0;}  
    
}

@media only screen 
    and (min-width: 1440px)
	and (max-height: 960px) 
	and (min-height: 760px) {

	body#amg_admin  { font-size: 1.625dvh; }

}

@media only screen 
    and (min-width: 1024px)
	and (max-height: 640px) 
	and (min-height: 520px) {

	body#amg_admin  { font-size: 1.625dvh !important; /*font-size: 0.6875em;*/ }
		
}

@media only screen 
    and (max-device-width: 1366px)
	and (max-device-height: 1024px) 
	and (min-height: 1024px) {

	body#amg_admin  { font-size: 0.875em;  }
		
}

@media only screen
    and (min-width: 1441px) {
        
	body  { font-size: 1em; }
	
}
	

@media only screen
    and (min-width: 1921px) {

	body  { font-size: 1.125em; }     
    
}

@media only screen
    and (min-width: 2049px) {

    body  { font-size: 1vw; }    
}


@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
	and (orientation : landscape) {

	body { margin: 0;  }
}

@media only screen 
    and (device-width : 812px) 
    and (device-height : 375px) 
	and (orientation : landscape) {

	body { margin: 0;  }
}

@media only screen 
    and (device-width : 568px) 
    and (device-height : 320px) 
	and (orientation : landscape) {

	body { margin: 0; }
}

@media only screen 
	and (max-width: 896px) { 

	body { margin: 0; }
}

@media only screen 
    and (device-width : 414px) 
    and (device-height : 896px) 
	and (max-width: 896px)
    and (-webkit-device-pixel-ratio : 3) { 
		
	body { margin: 0; }
}


