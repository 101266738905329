html { width: 100%; height: auto; }
html, body{height: 100%}

body { width: 100%; height: auto; margin: 0; padding: 0; overflow-x: hidden; overflow-y: auto; direction: ltr; position: relative; text-align: left; word-wrap: break-word; color-scheme: dark;  }

body > div { position: relative;height: 100%; overflow-x: hidden; box-sizing: border-box; overflow-y: auto; }

/************Reset Css*********************/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: inherit;
	vertical-align: baseline;
	user-select: none;
}

/******************Global***********************/

img { margin: 0; max-width: 100%; border: 0 solid transparent; }
h1, h2, h3, h4 , h5 {margin: 0 0 0;padding: 0 0 0;font-weight: 700;}

* { box-sizing: border-box;  }

/***********
* { -webkit-transition: all .1s 1s, visibility 0s 0s; -moz-transition: all .1s 1s, visibility 0s 0s; 	transition: all .1s 1s, visibility 0s 0s; box-sizing: border-box; }
***********/

* :focus { outline: none; }

/**************Skeleton*********************/

/***************************/

#amg_admin > * ::-webkit-scrollbar { width: 1em; height: 1em; border: 1px solid transparent; font-size: 1em; }
#amg_admin > * ::-webkit-scrollbar-track{ background: var(--clrscrolltrack-gradient); border: calc(0.75em - 0.5px) solid transparent; border-radius:0.5em; background-clip: content-box; }
#amg_admin > * ::-webkit-scrollbar-thumb { width: calc(1em - 2px); height: calc(1em - 2px); cursor: pointer; background: var(--clrscrollthumb-gradient); background-clip: content-box; border: 0.325em solid transparent; border-radius: 1em;  }
#amg_admin > * ::-webkit-scrollbar-thumb:hover {  background: var(--clrscrollthumb-solid); background-clip: content-box;  }

/*********grid-sys**************/

.g-rw {display: flex;width: 100%;flex-wrap: wrap;flex-direction: row;gap: 1em;align-content: flex-start;}
[class^="clm-"], [class*=" clm-"] { display: flex;  flex-direction: column; gap: 1em; }

/*******************************************/


#main-wrapper { width: 100%; height: auto; position: relative; top: 0; bottom: 0; left: 0; right: 0; overflow-x: hidden; box-sizing: border-box; overflow-y: auto; z-index: 1;  }


#app-comp { width: 100%; height: auto; overflow: hidden; }

.web-portal-container { width: calc(100% - 8em);  max-width: 120em; margin: 0 auto; }
.screen-main-reg { margin: 0; padding: 0 0 2.75em; }
/* .location-inner-reg { z-index: 1; position: relative; } */
.location-inner-reg .web-portal-container { position: relative; max-width: inherit; width: calc(100% - 3em); z-index: 1; }
.web-portal-container.header,  .web-portal-container.footer { position: relative; max-width: inherit; width: calc(100% - 6em);  }
.screen-content-header {margin: 0;padding: 2em 0;color: #fff;position: relative;display: flex;flex-direction: column;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}
.screen-content-header::after {display: block;content: '';position: absolute;width: 100vw;height: 100%; background: transparent; top: 0;z-index: -1;}

.title-bar { width: 100%; padding: 0 0 1em 0; display: flex; flex-direction: row; align-content: center; justify-content: space-between; align-items: flex-start; gap: 1em;}

header#amg-header { position: fixed; top: 0;left: 0;right: 0; width: auto;height: 5.5em; min-height: inherit;  padding: 0 0;margin: 0;background: #000000bb;box-shadow: 0 0.125em 0.25em #00000040;z-index: 2;}
header#amg-header:after {display: block; position:absolute; content: '';left: 0;right: 0;top: 0;bottom: 0;z-index: -1;-webkit-backdrop-filter: blur(1em);backdrop-filter: blur(1em);background: #00000080;}

#main-section { width: 100%; height: auto; /*overflow-x: hidden; overflow-y: auto;*/ padding: 5.5em 0 0; }
#amg-content-region {position: static;z-index: 1;min-height: calc(100vh - 10em);max-height: max-content;height: fit-content;padding: 0 0 0;}
#content-inner {padding: 0;height: fit-content;/* min-height: calc(100vh - 9em); */position: static;}
.content-wrapper { width: 100%; background: #1f1f1ff2; margin: 0; padding: 0; color: #fff;  min-height: calc(100vh - 12em); }

.amg-row  {display: flex;flex-direction: row;gap: 1em;flex-wrap: wrap;justify-content: space-between;align-content: flex-start;align-items: flex-start;padding: 0 0 1em;width: 100%;} 
.amg-row.no-gutter { gap: 0; }

.amg-row-w-g.h-streched-flx, .amg-row-no-g.h-streched-flx { align-items: stretch; }

.lt-row {display: flex;flex-direction: row;gap: 0; flex-wrap: wrap;justify-content: flex-start;align-content: flex-start;align-items: flex-start;padding: 0 0 1em; width: 100%; }
.lt-row-w-g  {display: flex;flex-direction: row;gap: 1em;flex-wrap: wrap;justify-content: space-between;align-content: flex-start;align-items: flex-start;padding: 0 0 1em;width: 100%;} 
.lt-row.right {align-content: center;align-items: flex-end;display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: flex-end;}
.lt-row.space-between {align-content: center;align-items: flex-end;display: flex;flex-direction: row;flex-wrap: nowrap;justify-content: space-between;}
.lt-row.left, .lt-row-w-g.left  {align-content: center; align-items: flex-start; justify-content: start}

.lt-row .clm-auto .lt-row { justify-content: flex-start; }

.ft-row { display: flex; flex-direction: row; flex-wrap: wrap; align-content: center; justify-content: space-between; align-items: center; }

.popup-head { display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding: 0.75em 1.5em; background-color: var(--bgprimary); flex-wrap: nowrap; align-content: center;}

.amg-clm { display: flex; flex-direction: column; gap: 1em; flex-wrap: wrap; } 

#amg_admin .leaflet-verticalcenter {  z-index: 19999; }


.clm-auto { width: auto; }
.clm-auto.strech { width: auto; flex: 1 1 auto; }
/*
img { width: 100%; height: 100%; object-fit: cover; max-width: 100%; }
*/

#amg_admin .slides-container > * ::-webkit-scrollbar { width:0.5em; height: 0.5em; }
#amg_admin .slides-container > * ::-webkit-scrollbar-track{ background: #ff00004c; -webkit-box-shadow: inset 0px 0px 0px #00000017; border-radius:0.75em;}  
#amg_admin .slides-container > * ::-webkit-scrollbar-thumb { width: auto; margin: 0; cursor: pointer; background: #00000000; border-radius: 0.75em;  border: 1px solid transparent; }



@media only screen and (min-width: 1280px) {
    .desk-only { display: block; }
    .mob-only { display: none; }
	[class^="clm-tab-"], [class*=" clm-tab-"] { width: inherit; }
	.clm-1 { width: 8.33%; }
	.clm-2 { width: 16.66%; }
	.clm-3 { width: 25%; }
	.clm-4 { width: 33.33%; }
	.clm-5 { width: 41.66%; }
	.clm-6 { width: 50%;}
	.clm-7 { width: 58.33%; }
	.clm-8 { width: 66.66%; }
	.clm-9 { width: 75%; }
	.clm-10 { width: 83.33%; }
	.clm-11 { width: 91.66%; }
	.clm-12 { width: 100%; }
	.clm-auto { width: auto; }

	.lt-row-w-g > .clm-1 { width: calc(8.33% - 1em); }
	.lt-row-w-g > .clm-2 { width: calc(16.66% - 1em); }
	.lt-row-w-g > .clm-3 { width: calc(25% - 1em);}
	.lt-row-w-g > .clm-4 { width: calc(33.33% - 1em); }
	.lt-row-w-g > .clm-5 { width: calc(41.66% - 1em); }
	.lt-row-w-g > .clm-6 { width: calc(50% - 1em);}
	.lt-row-w-g > .clm-7 { width: calc(58.33% - 1em); }
	.lt-row-w-g > .clm-8 { width: calc(66.66% - 1em); }
	.lt-row-w-g > .clm-9 { width: calc(75% - 1em); }
	.lt-row-w-g > .clm-10 { width: calc(83.33% - 1em); }
	.lt-row-w-g > .clm-11 { width: calc(91.66% - 1em); }
	.lt-row-w-g > .clm-12 { width: calc(100% - 1em); }


}

@media only screen and (max-width: 1279px) {

	.desk-only { display: none; }
#header-nav-set {transform: translateX(110vw); display: flex;flex-direction: column-reverse;position: fixed;top: 5.5em;left: 0;right: 0;bottom: 0;width: 100%; flex-wrap: nowrap;align-content: center;justify-content: flex-end;align-items: flex-end;padding: 1.5em 5em 2em;background: #10101040;backdrop-filter: blur(1em);gap: 3em;border-radius: 0 0 0.5em 0.5em;box-shadow: 0 0.5em 0.25em #00000080;z-index: 4; transition: all 0.2s linear;}
#header-contents.show-m-menu #header-nav-set { transform: translateX(0); transition: all 0.2s linear; }


	.clm-1 { width: 8.33%; }
	.clm-2 { width: 16.66%; }
	.clm-3 { width: 25%; }
	.clm-4 { width: 33.33%; }
	.clm-5 { width: 41.66%; }
	.clm-6 { width: 50%;}
	.clm-7 { width: 58.33%; }
	.clm-8 { width: 66.66%; }
	.clm-9 { width: 75%; }
	.clm-10 { width: 83.33%; }
	.clm-11 { width: 91.66%; }
	.clm-12 { width: 100%; }
	.clm-auto { width: auto; }

	.lt-row-w-g > .clm-1 { width: calc(8.33% - 1em); }
	.lt-row-w-g > .clm-2 { width: calc(16.66% - 1em); }
	.lt-row-w-g > .clm-3 { width: calc(25% - 1em);}
	.lt-row-w-g > .clm-4 { width: calc(33.33% - 1em); }
	.lt-row-w-g > .clm-5 { width: calc(41.66% - 1em); }
	.lt-row-w-g > .clm-6 { width: calc(50% - 1em);}
	.lt-row-w-g > .clm-7 { width: calc(58.33% - 1em); }
	.lt-row-w-g > .clm-8 { width: calc(66.66% - 1em); }
	.lt-row-w-g > .clm-9 { width: calc(75% - 1em); }
	.lt-row-w-g > .clm-10 { width: calc(83.33% - 1em); }
	.lt-row-w-g > .clm-11 { width: calc(91.66% - 1em); }
	.lt-row-w-g > .clm-12 { width: calc(100% - 1em); }


}

/********** For Mozilla Browser ***************/

@-moz-document url-prefix() {

	#amg_admin { scroll-behavior: smooth;  scrollbar-width: thin; scrollbar-color: #64646450 transparent;}

}
  