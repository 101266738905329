.icon.big { width: 3em; height: 3em; display: block; flex: 0 0 3em; }

.brand-logo-mb { background: url(../../images/mb_logo-01.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.brand-logo-amg { background: url(../../images/amg-logo.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }

/* .banner_section .hero-img-banner::before { background: url(../../images/banner.png) no-repeat scroll 50% 50% transparent; background-size: cover;} */

#info-btn { background: url(../../images/icon_info.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.prof-image { background: url(../../images/icon_user.png); background-position: 50% 50%; background-color: #aaa; background-repeat: no-repeat; background-size: cover;  }

.icon.delete { background: url(../../images/delete.png) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon.edit { background: url(../../images/edit.png) no-repeat scroll 50% 50% transparent; background-size: contain; }

.icon_members  { background: url(../../images/icon_member.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_participants { background: url(../../images/participants-new.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_hashtaq { background: url(../../images/hashtag-new.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_operator { background: url(../../images/info.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_add { background: url(../../images/icon_add.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_qrcode { background: url(../../images/icon_qrcode.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }

.icon_more { background: url(../../images/icon_more.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_track { background: url(../../images/icon_track.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.upload-icon {background: url(../../../assets/locations/icn-add-image.png) no-repeat scroll 50% 50% transparent; background-size: contain;}


.icon_default { background: url(../../images/exercise-icon-2.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_besttime { background: url(../../images/Best-Time.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_location {  background: url(../../images/icon_location.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_efficiency {  background: url(../../images/Efficiency.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_consistancy {  background: url(../../images/Consistancy.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_score{ background: url(../../images/score.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_addscore{ background: url(../../../assets/images/add.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_drift{ background: url(../../../assets/images/drift.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_substract{ background: url(../../../assets/images/subtract.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_logout {  background: url(../../images/icon_logout.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_info { background: url(../../images/alias/information.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_edit { background: url(../../images/icon_edit.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_delete { background: url(../../images/icon_delete.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_back_arrow { background: url(../../images/icon_back_arrow.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_down_arrow { background: url(../../images/icon_down_arrow.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_kebab {  background: url(../../images/icon_kebab.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_redirect{ background: url(../../images/alias/resize.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_search { background: url(../../images/icon_search.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_add_item { background: url(../../images/icon_add_item.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_dealer_flow { background: url(../../images/icon_emotion_tour.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_dealer_add { background: url(../../../assets/images/dealer-add.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_start_flag{ background: url(../../../assets/locations/start-flag.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_dest_flag{ background: url(../../../assets/locations/destination-flag.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_highlight_flag{ background: url(../../../assets/locations/highlight-flag.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}

.icon_emotion_tour { background: url(../../images/icon_emotion_tour.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_off_road { background: url(../../images/icon_off_road.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_input_close, .icon_close { background: url(../../images/icon_input_close.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }

.upload-image { background: url(../../images/icon_upload_image.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_upload { background: url(../../images/icon_upload.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_refresh { background:url(../../../assets/images/reload.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_download { background:url(../../../assets/images/downloadicon.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_cone { background:url(../../../assets/images/cone.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_stop_box { background:url(../../../assets/images/offtrack.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}

.icon_location_n { background: url(../../locations/icn-location.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_call { background: url(../../locations/icn-call.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_website { background: url(../../locations/icn-website.png) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_email { background: url(../../locations/icn-email.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_close { background: url(../../../assets/images/icon-close.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_fwd_arrow { background: url(../../../assets/images/arrow-forward.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}

.icon_start_white { background: url(../../../assets/locations/start-flag.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_loc_delete { background: url(../../../assets/locations/delete1.png) no-repeat scroll 50% 50% transparent; background-size: contain; }

.icon_round_trip {  background: url(../../../assets/locations/roundtrip-navigation.png) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_add_stroke { background: url(../../../assets/images/icon_add_stroke.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}

.icon_amg_min { background: url(../../../assets/images/icon_amg_min.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_poi_pin { background: url(../../../assets/locations/poi_pin.svg) no-repeat scroll 50% 50% transparent; background-size: 1.75em; }
.icon_info_round { background: url(../../../assets/locations/info1.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_info_red { background: url(../../../assets/locations/info_red.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_recenter_homePage { background: url(../../../assets/images/current-location.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_recenter { background: url(../../../assets/images/recenter.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_edit_card { background: url(../../../assets/images/pencil_grey.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_img_galley {background: url(../../../assets/images/image_gallery.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_recent_search {background: url(../../../assets/images/icon_recent_search.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_audio_upload {background: url(../../../assets/images/audio-upload.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_spinner { background: url(../../images/icon_spinner.png) no-repeat scroll 50% 50% transparent; background-size: contain;} 
.icon_globe {background: url(../../images/globe.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}

.arrow-left { background: url(../../images/chevron-left.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.arrow-right { background: url(../../images/chevron-right.svg) no-repeat scroll 50% 50% transparent; background-size: contain; }
.icon_link {background: url(../../images/link.svg) no-repeat scroll 50% 50% transparent; background-size: contain;}
.icon_up_arrow {background: url(../../images/icon_down_arrow.svg) no-repeat scroll 50% 50% transparent; background-size: contain; transform: rotate(180deg);}

.geo-fence-spotter { width: 1.5em; height: 1.5em; display: block; flex: 1 1 1.5em; }
.geo-fence-spotter.rectangle { background: url(../../images/rectangle.png) no-repeat scroll 50% 50% transparent; background-size: 1.325em; }
.geo-fence-spotter.radius { background: url(../../images/circle.png) no-repeat scroll 50% 50% transparent; background-size: 1.325em; }
.geo-fence-spotter.reset-geo { background: url(../../images/fencedelete.png) no-repeat scroll 50% 50% transparent; background-size: 1em; }

.download_icon { width: 2.5em; height: 2.5em; display: block; flex: 0 0 2.5em; }
.download_icon.icon_zip {background: url(../../locations/icn-zip-small.png) no-repeat scroll 50% 50% transparent; background-size: 1.5em; }
.download_icon.icon_download {background: url(../../images/icn-download.png) no-repeat scroll 50% 50% transparent; background-size: 1.5em; }

.download_icon.atn-btn { cursor: pointer;}

