
#amg_admin .ngb-dp-arrow button:disabled { background: transparent;}
#amg_admin .dropdown-menu {width: auto;min-width: auto;right: 0;/* display: flex; */ flex-direction: column;flex-wrap: nowrap;align-items: center;align-content: center;justify-content: center; font-size: 1em;}

#amg_admin .dropdown-menu button {  width: 100%; font-size: 1em;  }
#amg_admin .dropdown-menu button.circuit-btn { min-width: 10em; text-align: left; justify-content: start;}

.map-container { width: 100%; height: 100%; }

#amg_admin .leaflet-container { font-size: 0.75em !important;}
#amg_admin .leaflet-control-container { position: absolute; left: 2em; right: 2em; top: 2em; bottom: 2em;}
#amg_admin .leaflet-control-zoom.leaflet-bar.leaflet-control { bottom: calc(50vh - var(--header-height) - 5em); margin-bottom: 0; margin-right: 0; border: solid 0.285em #fff; background-color: #fff; display: flex; flex-direction: column; flex-wrap: nowrap; justify-content: center; align-items: center; gap: 0.25em; gap: 0.25em; box-shadow: 0 0 0.25em #00000060; }

.map-container-main { width: 100%; height: fit-content; min-height: calc(100vh - 5.5em); position: relative; z-index: 0; display: flex; flex-direction: row; align-items: center; justify-content: center; flex-wrap: nowrap; align-content: center; }
.no-content{font-size: 1.5em; color: #808080; text-align: center;}

.event-footer-item .icon { width: 1.5em; height: 1.5em; }

span.exercise-name { max-width:100% ; overflow: hidden;  text-overflow: clip;}

.location-sub-details-inner {padding: 5em 0 2em;}
.sub-content {min-height: calc(100vh - 17em);display: flex;flex-direction: column;flex-wrap: nowrap;justify-content: flex-start;padding: 2em 0 0;}
.map-layout-screen { position: relative; z-index: 1;}
#amg_admin .btn-group, #amg_admin .btn-group-vertical { display: inline-flex; vertical-align: middle; flex-wrap: wrap; gap: 1em 2em;}

#amg_admin .leaflet-touch .leaflet-control-zoom-in, 
#amg_admin .leaflet-touch .leaflet-control-zoom-out { font-size: 1.25em;}

#amg_admin .tooltip { font-family: 'MB-Corp-web', sans-serif; font-weight: 500; }

.title-area.et-frm-row > div { max-width: calc(50% - 0.5em);}

#create-et-main { padding: 0 0 0 32em; position: relative; }

.circuit-details-main { max-height: 100%; width: 100%; display: flex; flex-direction: column;}
.circuit-info-main { padding: 0 0 2em; }

.pg-widget-main { width: 100%; height: 100%; position: relative;}

.loader-region { width: 100%; height: auto; min-height: 10em; display: flex; flex-direction: column; align-content: center; justify-content: center; align-items: center; }

#create-et-main #carousel-region { position: absolute; left: 32em; right: 0; width: auto;}
#create-et-main #carousel-region .image-card-carousel { position: absolute; width: 100%; left: 0; right: 0;  }

#carousel-region .image-card-carousel .slick-slider .slick-list { padding-left: 2em; /* padding-bottom: 0.5em; overflow-x: auto; */}
#amg_admin #carousel-region .image-card-carousel .slick-slider .slick-list::-webkit-scrollbar { width:0.125em; height: 0.125em; transform: translate(0, 1em); }
#amg_admin #carousel-region .image-card-carousel .slick-slider .slick-list::-webkit-scrollbar-track{ background: #00000000; -webkit-box-shadow: inset 0px 0px 0px #00000017; border-radius:0.75em;}  
#amg_admin #carousel-region .image-card-carousel .slick-slider .slick-list::-webkit-scrollbar-thumb { width: auto; margin: 0; cursor: pointer; background: var(--clrscrollthumb); border-radius: 0.75em;  border: 1px solid transparent; }

.left-panel-wrapper, .right-floating-wrapper { display: flex; max-height: 100%; position: absolute; top: 0; color: #fff; z-index: 10000;}
.left-panel-wrapper { width: var(--left-floating-panel-width); left: 0; bottom: 0; flex-direction: column; }
.right-floating-wrapper { width: fit-content;  width: -webkit-fit-content; right: 0; background: var(--bgprimary); }
.circuit-widget-main { display: flex; flex-direction: column; flex-wrap: nowrap; width: 100%; max-height: 100%; gap: 0; justify-content: flex-start; align-content: flex-start; flex: 1 0 auto;}
.modal-class-main, 
.modal-class-circuit { overflow-y: auto; overflow-x: hidden; width: 100%; position: relative; display: flex; flex-direction: column; flex: 0 1 auto; background: var(--left-panel-bg), #00000040; backdrop-filter: blur(0.625em); -webkit-backdrop-filter: blur(0.625em); padding: 0 0 1em; }

.modal-class-main { max-height: 100%;}
.modal-class-circuit { max-height: calc(100vh - 24em);}

.widget-title { display: flex; padding: 1.5em 1.5em 0.5em; flex-direction: row; flex-wrap: nowrap; align-items: flex-end; justify-content: space-between; }
.form-content { width: 100%; }

.modal-class-search { padding-bottom: 0.5em; margin: 0; height: fit-content; height: -webkit-fit-content;}

.info-block-main { padding: 1em 1.5em 1.25em; max-height: 100%; overflow-y: auto; }
.links-block { display: flex; align-items: flex-start; justify-content: center; gap: 0.5em; flex-direction: column; padding: 0 0 0.5em;}
.links-block label, .info-item-block label  { font-weight: bold; color: var(--textgrey1) !important; letter-spacing: 0; font-size: 0.875em;}
.links-block .link-section { display: flex; gap: 0.5em; max-width: 100%;}
.links-block a  { display: -webkit-box; max-width: calc(100% - 2em); -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; margin-bottom: 0; text-decoration: none; color: #fff; }
.links-block .copy-link  { width: 1em; height: 1em; background-image: url(../../events/copy.png); background-size: cover; display: flex; align-self: center; margin-bottom: 0.2em; }

.in-page-main .web-portal-container { width: calc(100% - 8em);  max-width: 120em; margin: 0 auto; position: static;}
#amg_admin .form-check { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; gap: 1em; padding: 0; margin: 0;}
#amg_admin .form-check .form-check-input { margin: 0;}
#amg_admin .form-style label { padding: 0; }
#amg_admin .form-control { font-weight: 500;}

.map-container-main .map-pane { width: 100vw; height: 100%; position: absolute; box-shadow: inset 0 0 4em 2em var(--bgprimary); }

.sp-btw { justify-content: space-between; }
.v-aln-base { align-items: baseline;}


#amg_admin .modal-header { background:  var(--bgsecondary); flex-direction: row; flex-wrap: nowrap; padding: 0.5em !important; align-items: center; align-content: center; justify-content: space-between; gap: 1em;}
#amg_admin .modal-header .modal-title { color: #fff; font-weight: 700;}


#amg_admin .modal-content.alert-modal { background: var(--bgalertmodal);}
#amg_admin .alert-modal .modal-header { background: transparent; text-align: center;  justify-content: center; width: 100%;}

.img-upload-container { border: 1px solid #979797; height: fit-content; width: 100%; padding: 2em; display: flex; text-align: center; vertical-align: middle; position: relative; flex-direction: column; gap: 1em; flex-wrap: nowrap; align-content: center; justify-content: center; align-items: center; min-height: 14em; aspect-ratio: 12/5; }
.img-upload-container .image-preview { position: absolute; width: 100%; height: 100%; object-fit: cover; }

/*
.location-inner-reg .web-portal-container { position: relative; max-width: none; width: calc(100% - 3em);  }
*/

.panel-box {  display: flex; flex-direction: column; flex-wrap: nowrap;  align-content: flex-start;  justify-content: flex-start; align-items: flex-start; width: 100%; gap: 1em; }
.panel-box p { margin: 0; padding: 0; font-size: 0.875em; }
.panel-row { display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start; align-content: flex-start; align-items: center; gap: 0.5em; width: 100%;}
.panel-row h6 { margin: 0; padding: 0; font-size: 0.875em;} 
.panel-row span { color: #fff;}
.panel-row p { margin: 0;}

.panel-row.center { justify-content: center; }

.carousel-item-parent .panel-box { gap: 0.625em; }

.result-sec { position: relative; width: 100%; height: 0.25em; }

.search-reg-main { position: relative; z-index: 1000; }
.search-reg-main .search-result.main-div { position: absolute; min-width: 100%; }

.title-bar.location-address-bar { border-bottom: 1px solid #666; margin: 0 0 1em; justify-content: flex-start; }
span.loc-title { white-space: nowrap; overflow-wrap: break-word; width: fit-content; width: -webkit-fit-content; max-width: calc(100% - 6em); text-overflow: ellipsis; overflow: hidden; }

.search-loc-form-txt, .search-loc-form-txt.form-item.material  { margin: 0; padding: 0; }
.search-loc-form-txt textarea { margin: 0; border: 0 solid transparent; }

.dealerflow-create-modal button.beta:disabled, 
.dealerflow-create-modal .button.beta:disabled { background: var(--clrsecondary) !important;}

.back-button-block { padding: 0.5em; }
.left-panel-wrapper .form-item { width: 100%;}

.left-panel-wrapper .button-region { padding: 0 0 1em;}

.info-item-block { display: grid; padding: 0.75em 0; gap: 2em 1em; }
.info-item-block.single-clmn { grid-template-columns: 100%;  }
.info-item-block.double-clmn { grid-template-columns: calc(50% - 0.5em) calc(50% - 0.5em);  }
.info-item-block.triple-clmn { grid-template-columns: calc(33% - 0.5em) calc(33% - 0.5em) calc(33% - 0.5em);  }

.form-block form { width: 100%; display: flex; flex-direction: column; gap: 1em; }

.back-button-block, .info-block-main, .modal-class-main { backdrop-filter: blur(1em);}

.back-button-block { background: var(--bgbacksec);}
.info-block-main { background: var(--left-panel-bg);}

.map-overlay-main { position: absolute; overflow-y: auto; overflow-x: hidden; width: auto; top: 1em; left: 0; right: 0; border-radius: 0em; height: calc(100vh - 22.5em); }
.modal-content-in { width: 100%; padding: 2em 1.5em; display: flex; flex-direction: column; gap: 1.5em;} 	

.left-panel-wrapper .img-upload-container { width: 5em; min-height: 5em; aspect-ratio: 1;}
.widget-title-bar {  display: flex; width: 100%; justify-content: space-between; align-items: flex-end; padding: 1em 0 0.5em; }
.widget-title-bar h2 { font-size: 1.75em; color: #fff; font-weight: bold !important; margin: 0; padding: 0 0 0.25em; letter-spacing: 0.05em; }

.view-details { display: flex; width: 100%; justify-content: space-between; align-items: flex-end; padding: 0 1.5em; }
.view-details p.loc-title { font-size: 1.75em; color: #fff;  margin: 0; padding: 0 0 0.25em; }
.view-details p.loc-details { font-size: 1em; color: #fff; margin: 0; padding: 0 0 0.25em; }
.view-details small { font-size: 1em; color: var(--textgrey1); margin: 0; padding: 0 0 0.25em; }

.view-circuit-details p.loc-title { font-size: 1.75em; color: #fff;  margin: 0; padding: 0 0 0.25em; font-weight: 500; max-width: 100%; word-break: break-all; }
.view-circuit-details p.loc-details { font-size: 1em; color: #fff !important; margin: 0; padding: 0 0 0.25em; max-width: 100%; word-break: break-all; }

.map-overlay-main { position: absolute; overflow-y: auto; overflow-x: hidden; width: auto; top: 1em; left: 0; right: 0; border-radius: 0em; height: calc(100vh - 23.5em); }
.modal-content-in { width: 100%; padding: 2em 1.5em; display: flex; flex-direction: column; gap: 1.5em;} 	

.left-panel-wrapper .img-upload-container { width: 5em; min-height: 5em; aspect-ratio: 1;}

#amg_admin .leaflet-touch .leaflet-bar a { width: 2.5em; height: 2.5em; line-height: 2.5em; font-size: 1.5em; margin: 0; }
#amg_admin .leaflet-touch .leaflet-bar a span { font-size: 1.25em; }

.not-empty textarea { min-height: 16em; }

.loading-ani { animation: loading-spinner 2s ease-in-out infinite;}

.button-region:empty { display: none;}

.arrow-left, 
.arrow-right {  width: 2em; height: 2em; display: block; padding: 0; margin: 0; } 

.loader-block { padding: 1em; display: flex; width: 100%; height: 100%; min-height: 13.25em; flex-direction: column; flex-grow: nowrap; justify-content: flex-end; gap: 0.5em;} 
.widget-section .form-row { padding: 0 0 1em;}

#amg_admin .img-cropper-wrapper { min-height: 50svh; height: fit-content;}

#amg_admin image-cropper { position: relative; max-height: calc(100svh - 5em - 8em); min-height: 50svh; align-items: center;  }
#amg_admin image-cropper > div { height: 100%; max-height: calc(100svh - 5em - 8em); display: flex; align-items: center; justify-content: center; position: relative; }
#amg_admin .img-cropper-wrapper:has(.upload_error) image-cropper > div { min-height: 50svh; align-items: center; }

#amg_admin .img-cropper-wrapper:has(.upload_error) .ngx-ic-cropper, #amg_admin .img-cropper-wrapper:has(.upload_error) .ngx-ic-overlay { width: 100% !important; height: 100% !important; margin: 0 !important; left: 0 !important; top: 0 !important;  }
#amg_admin .img-cropper-wrapper:has(.upload_error) .ngx-ic-cropper { outline: 1px dashed #ff0000;}

#amg_admin:has(.open-modal), #amg_admin:has(.modal-open) { overflow: hidden;}

#amg_admin:has(#screen_language){
	
}

@keyframes loading-spinner {
	0% {
		transform: rotate(0deg)
	}

	100% {
		transform: rotate(360deg)
	}
}

#content-inner .sub-menu-container *::-webkit-scrollbar { width: 0.25em;  height: 0.25em; }
#content-inner .sub-menu-container *::-webkit-scrollbar-track { background-color: var(--bgprimary)cc; }
#content-inner .sub-menu-container *::-webkit-scrollbar-thumb { border-radius: 2em; box-shadow: inset 0.25em 0.125em 0.75em 0 #51515131; }

.bottom-left span a { color: var(--textgrey1); text-decoration: underline;}

/* #amg_admin .web-portal-container h3 { font-size: 1.75em;}
#amg_admin .web-portal-container h4 { font-size: 1.5em;}
#amg_admin .web-portal-container h5 { font-size: 1.25em;}
#amg_admin .web-portal-container h6 { font-size: 1.125em;} */

@media only screen 
	and (min-width:581px) 
	and (max-width:1279px) {
	
    body { margin: 0;}

}


@media only screen 
	and (min-width:581px) 
	and (max-width:768px) {   
	body { margin: 0;}
}


@media only screen 
	and (min-width:769px)
	and (max-width:959px) {
	body { margin: 0;}
}

@media only screen 
	and (min-width: 960px)
	and (max-width: 1024px) {
	body { margin: 0;}	
}

@media only screen 
	and (min-width:1025px) 
	and (max-width:1279px) {
	body  { font-size: 0.675em; }
}

@media only screen 
	and (min-width:1280px) {	
	body { margin: 0;}	
}

@media only screen 
	and (min-width:1280px)
	and (max-width:1440px)	{	
	body { margin: 0;}	
}


@media only screen 
    and (device-width: 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 

	body { margin: 0;}	
}

@media only screen 
    and (min-width: 800px) 
    and (height : 600px) { 
	body { margin: 0;}	
}


@media only screen
    and (max-width: 1024px) {
	body { margin: 0;}
}

@media only screen
	and (max-device-width: 1024px)
    and (max-width: 768px) {
    body { margin: 0;}        
}

@media only screen
	and (max-device-width: 1024px)
	and (max-width: 768px)
	and (device-height: 1024px)
    and (orientation: portrait) {
	body { margin: 0;}   
}

@media only screen
	and (max-device-width: 1024px)
	and (min-width: 1024px)
    and (orientation: portrait) {
    body { margin: 0;} 
}

@media only screen 
    and (min-width: 600px)
    and (orientation: portrait) {
	body { margin: 0;}
}

@media only screen
    and (min-width: 1441px) {
	body  { font-size: 1em; }	
}
	

@media only screen
    and (min-width: 1921px) {
	body  { font-size: 1.125em; }      
}

@media only screen
    and (min-width: 2049px) {
    body  { font-size: 1vw; }    
}


@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
	and (orientation : landscape) {
	
	body { margin: 0;}
}

@media only screen 
    and (device-width : 812px) 
    and (device-height : 375px) 
	and (orientation : landscape) {

	body { margin: 0; }
}

@media only screen 
    and (device-width : 568px) 
    and (device-height : 320px) 
	and (orientation : landscape) {

	body { margin: 0; }
}

@media only screen 
	and (max-width: 896px) { 

	body { margin: 0;  }
}

@media only screen 
    and (device-width : 414px) 
    and (device-height : 896px) 
	and (max-width: 896px)
    and (-webkit-device-pixel-ratio : 3) { 

	body { margin: 0;  }
}

.circuits-search-container:has(#spinner) {
	height: auto;
}

.carousel-wrapper:has(.open-modal) {
	z-index: 10001;
}
