@use 'sass:map';
@use '../core/style/private';
@use '../core/theming/theming';

@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .mat-autocomplete-panel {
    @include private.private-theme-overridable-elevation(4, $config);
    background: theming.get-color-from-palette($background, card);
    color: theming.get-color-from-palette($foreground, text);

    // Selected options in autocompletes should not be gray, but we
    // only want to override the background for selected options if
    // they are *not* in hover or focus state. This change has to be
    // made here because base option styles are shared between the
    // autocomplete and the select.
    .mat-option.mat-selected:not(.mat-active):not(:hover) {
      background: theming.get-color-from-palette($background, card);

      &:not(.mat-option-disabled) {
        color: theming.get-color-from-palette($foreground, text);
      }
    }
  }
}

@mixin typography($config-or-theme) {}

@mixin _density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-autocomplete') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
