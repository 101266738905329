body#amg_admin, 
body { font-size: 1em; font-family: 'MB-Corp-web', sans-serif; font-weight: 500; font-style: normal; font-display: auto; -webkit-font-smoothing: antialiased; text-rendering: optimizeLegibility; }


h1,h2 {  font-family: 'MB-Corp-web', sans-serif; font-weight: 800 !important; }
h3,h4,h5,h6 {  font-family: 'MB-Corp-web', sans-serif; font-weight: 700 !important; }
h1 { font-size: 2.5em;}
h2 { font-size: 2em;}
h3 { font-size: 1.75em;}
h4 { font-size: 1.5em;}
h5 { font-size: 1.25em;}
h6 { font-size: 1em; }

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


/**********Regular***********/


@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Regular-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Regular-Web.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Regular-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Regular-Web.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Regular-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Regular-Web.woff) format('woff');
  font-weight: normal;
}


/**********Light***********/
@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Light-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Light-Web.woff) format('woff');
  font-weight: lighter;
}
@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Light-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Light-Web.woff) format('woff');
  font-weight: light;
}
@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Light-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Light-Web.woff) format('woff');
  font-weight: 300;
}


/**********Bold***********/
@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Bold-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Bold-Web.woff) format('woff');
  font-weight: bold;
}
@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Bold-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Bold-Web.woff) format('woff');
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-Bold-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-Bold-Web.woff) format('woff');
  font-weight: 700;
}

/**********Extrabold***********/
@font-face {
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-ExtraBold-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-ExtraBold-Web.woff) format('woff');
  font-weight: bolder;
}
@font-face {
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-ExtraBold-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-ExtraBold-Web.woff) format('woff');
  font-weight: 800;
}
@font-face {
  font-family: 'MB-Corp-web';
  src: url(../fonts/MB-CorpoS-ExtraBold-Web.woff2) format('woff2'), url(../fonts/MB-CorpoS-ExtraBold-Web.woff) format('woff');
  font-weight: 900;
}
