.web-portal-container { width: calc(100% - 4em); }
.amg-linklist { display: flex; flex-direction: column; flex-wrap: nowrap; align-content: flex-end; align-items: flex-end; justify-content: center;}

.lt-row, .lt-row-w-g { flex-direction: column;}
[class^="clm-"], [class*=" clm-"], [class^="ch-w-"], [class*=" ch-w-"] { width: 100%; }
#amg-content-region { min-height: calc(100% - 4em);}
#content-inner { padding: 0; }

.map-container-main { min-height: calc(100vh - 14em); }

#footer-container .amg-footer-to-top-link {position: absolute;left: 0;right: 0;top: -1em;width: 5em;height: 6em;margin: auto;background: #252525;padding: 1em;border-radius: 5em;display: flex;flex-direction: column-reverse;flex-wrap: nowrap;align-content: center;justify-content: center;align-items: center;}
/********************************************/
 
@media only screen 
	and (max-width: 320px) {

	body#amg_admin  { font-size: 2vw; }    
	body > div { margin: 0; }

}

@media only screen 
	and (max-width: 280px) {

	body#amg_admin  { font-size: 0.675em; }    
	body > div { margin: 0; }
	
}

@media only screen 
	and (min-width:321px) 
	and (max-width: 360px) {

	body#amg_admin   { font-size: 0.625em !important;  } 
	body > div { margin: 0; }
	
}

@media only screen 
	and (min-width:414px) 
	and (max-width: 420px) {

	body#amg_admin  { font-size: 0.8750em !important;}    
	body > div { margin: 0; }

	
}

@media only screen 
    and (device-width : 414px) 
    and (device-height : 736px) 
    and (-webkit-device-pixel-ratio : 3) { 

	body#amg_admin   { font-size: 0.8750em  !important;  }

}

@media only screen 
	and (min-width:421px) 
	and (max-width: 480px) {

	body#amg_admin   { font-size: 0.875em;  }
	body > div { margin: 0; }
	
	
}

@media only screen 
	and (min-width:320px) 
	and (max-width:359px) {
	    
	body#amg_admin   { font-size: 0.625em;  }  
	body > div { margin: 0; }
	
	
}

@media only screen 
	and (min-width:361px) 
	and (max-width:413px) {
	    
	body#amg_admin   { font-size:0.675em;  }  
	body > div { margin: 0; }

	
}

@media only screen 
	and (min-width:481px) 
	and (max-width:599px) {

	body#amg_admin   { font-size: 1em;  }
	body > div { margin: 0; }
	
}

@media only screen 
	and (min-width:481px) {

	body#amg_admin  { font-size: 1em;  }
	body > div { margin: 0; }
		
}

@media only screen 
    and (device-width: 375px) 
    and (device-height : 812px)  { 

	body#amg_admin   { font-size: 0.875em !important;  } 
}

@media only screen
    and (max-width: 1024px) {
    
	body > div { margin: 0; }
       
}

@media only screen
    and (max-width: 768px) {

	body > div { margin: 0; }
        
}

@media only screen 
    and (orientation: portrait) {
        
	body > div { margin: 0; }
    
}


@media only screen 
    and (min-width: 600px)
    and (orientation: portrait) {

	body > div { margin: 0; }
    
}

@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
	and (orientation : landscape) {

	body#amg_admin  { margin: 0;  }
}


@media only screen 
    and (device-width : 812px) 
    and (device-height : 375px) 
	and (orientation : landscape) {

	body#amg_admin  { margin: 0;  }
}

@media only screen 
    and (device-width : 568px) 
    and (device-height : 320px) 
	and (orientation : landscape) {

	body#amg_admin  {  margin: 0; }
}

