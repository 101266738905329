
/*/**************/

html { color-scheme: dark; }
body#amg_admin { background: var(--bgprimary); }
#header {background: #00000038;color: #fff;border-bottom-style: solid;border-bottom-width: 1px;border-image-source: linear-gradient(90deg, #00000000 0%, #d0d0d0a1 49%, #00000000 100%);border-image-slice: 1;backdrop-filter: blur(15px);box-shadow: 0 0.5em 1em #00000060;}

a { color: var(--clrprimary);}
a:hover { color: var(--clrsecondary);}

.title-bar { color: #fff; }
.sub-title-color {color: #fff; opacity: 0.5; }

.info-box.default { background: #0076AB;}
.info-box.success { background: #25B24A;}
.info-box.failed { background: #EF0707;}
.info-box.neutral { background: #707070;}